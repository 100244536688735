<template>
  <div>
    <material-table :headers="headers" :items="items">
      <template #flow="{ item }">
        <span
          >{{ item.flow
          }}<v-icon v-if="item.new == true" color="error" small
            >mdi-circle</v-icon
          ></span
        >
      </template>
      <template #status="{ item }">
        {{ item.status }}
      </template>
      <template #actions="{ item }">
        <v-icon @click="toPage(item.to)">mdi-eye</v-icon>
      </template>
    </material-table>
    <material-pagination :totalPages="3"></material-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        { text: "流程", value: "flow", sortable: false },
        { text: "年度", value: "year", sortable: false },
        { text: "版本", value: "No", sortable: false },
        { text: "單位", value: "group", sortable: false },
        { text: "狀態", value: "status", sortable: false },
        { text: "更新時間", value: "updatedAt", width: 100 },
        { text: "操作", value: "actions", sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["updatedAt"],
        sorDesc: [false],
      },
      items: [
        {
          flow: "配分設定",
          year: "2022",
          No: "01",
          group: "-",
          status: 0,
          updatedAt: "2022-01-12 10:00:39",
          new: false,
          to: "/allocation/setting/1",
        },
        {
          flow: "單位稽核領域",
          year: "2023",
          No: "01",
          group: "車險商品部",
          status: 0,
          updatedAt: "2022-01-12 10:36:39",
          new: false,
          to: "/audit-field/risk-type/1",
        },
      ],
    };
  },
  methods: {
    toPage(to) {
      this.$router.push(to);
    },
  },
};
</script>
